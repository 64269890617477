<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
                </div>
            </div>
            <el-table :data="users.data" class="mt-2">
                <el-table-column prop="phone" label="注册登录手机号" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.phone}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名/企业名称" sortable />
                <el-table-column prop="idCard" label="身份证号/统一社会信用代码" sortable />
                <el-table-column prop="createTime" label="注册时间" sortable :formatter="$moment.sdFormatter" width="120" />
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="text" class="text-danger" @click="enableUser(scope.row.id)">
                            解除禁用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
                :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
                @current-change="usersPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                user: {},
            };
        },
        methods: {
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/RegisteredUser/GetDisabledRegisteredUsers', {
                    params: {
                        key: this.key,
                        pageIndex: this.usersPagination.currentPage - 1,
                        pageSize: this.usersPagination.pageSize,
                    }
                });
                this.users = response.data;
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            async enableUser(id) {
                if (!confirm('确定要解除禁用吗？')) {
                    return;
                }

                await this.$axios.post('/api/RegisteredUser/EnableRegisteredUser', { id: id });
                this.loadUsers();
            },
        },
        created() {
            this.loadUsers();
        },
    };
</script>